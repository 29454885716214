.reports-content, .reports-alternative-content {
  .description {
    text-align: center;
    padding: 5px 30px;
    .value {
      font-size: 26px;
      height: 30px;
    }
    .title {
      font-size: 12px;
      font-weight: 500;
    }
  }
  .progress-wrapper {
    width: 70%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    .title {
      font-size: 13px;
      font-weight: 400;
    }
  }
  .card-header {
    font-size: 16px;
    color: $light-purple;
  }
  .card-menu {
    color: $light-purple;
  }
  .no-data {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: $light-purple;
  }
  .progress-label {
    text-align: right;
    min-width: 120px;
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
  }
  .linear-item {
    padding: 0 1px;
  }
  .multi-linear {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .flexContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pieChartContainer {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  .pie-label-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .pie-description {
      display: flex;
      align-items: center;
      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      span {
        font-size: 13px;
        margin-left: 5px;
      }
    }
  }
  .usage-wrapper {
    display: flex;
    align-items: center;
    padding: 4px 0;
    .name {
      min-width: 100px;
      width: 100px;
      text-align: right;
      margin-right: 20px;
    }
    .usage-item {
      margin: 0 3px;
      width: 20px;
      height: 20px;
      border-radius: 3px;
    }
  }
  .location-title-wrapper {
    font-weight: 300;
    padding: 10px 20px;
    background: #070C13 0% 0% no-repeat padding-box;
    box-shadow: 8px 8px 10px #0000003B;
    border-radius: 3px;
    .location-name {
      color: #FFFFFF;
      font-size: 22px;
    }
    .location-address {
      margin-left: 6px;
      color: #506C92;
      font-size: 16px;
    }
  }
  .kpi-item-wrapper {
    padding: 15px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .value-wrapper {
      display: flex;
      font-size: 16px;
      color: $success;
      .value {
        font-size: 45px;
        color: $white;
        line-height: 60px;
      }
      .arrow {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $success;
        margin-left: 10px;
        &.down {
          justify-content: flex-end;
          .percentage {
            margin-bottom: -15px;
          }
        }
        &.up {
          justify-content: flex-start;
          .percentage {
            margin-top: -12px;
          }
        }
      }
      &.booked {
        .value {
          color: $success;
        }
        .arrow {
          color: $white;
        }
      }
    }
    .type {
      font-size: 16px;
      color: #54606E;
    }
  }
}

.reportTiles-content {
  .location-name {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .description-wrapper {
    padding-left: 18%;
    padding-right: 18%;
    .description {
      text-align: center;
      .value {
        font-size: 24px;
        height: 30px;
      }
      .title {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
  .echarts-for-react {
    width: 100%;
  }
  .day-wrapper {
    padding-bottom: 30px;
    .day {
      flex: 1;
      color: $light-purple;
      &.active {
        color: $success;
        background-color: #121B2A;
      }
    }
  }
  .progress-label {
    text-align: right;
    color: $light-purple;
    min-width: 100px;
    font-size: 14px;
    font-weight: 400;
    margin-right: 20px;
  }
}

div[data-id="tooltip"] {
  background-color: #17263B !important;
}

div[data-id="tooltip"]:after {
  border-right-color: #17263B !important;
}