.user-container {
  margin: 80px 30px 0;
  .card-header {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: normal;
  }
  .sub-header {
    font-size: 16px;
  }
  .user-nav-item {
    padding: 10px;
    margin: 10px 0;
    background-color: #17263B;
    border-radius: 3px;
    border-left: 3px solid #17263B;
    cursor: pointer;
    .title {
      font-size: 15px;
      font-weight: normal;
    }
    &.active {
      border-left: 3px solid #3185d8;
      background-color: #070c13;
    }
  }
  .user-content {
    width: 100%;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: $black;
    padding: 20px 30px;
    .detail-title {
      font-size: 15px;
    }
    .strength-bar {
      width: 250px;
      display: flex;
      align-items: center;
      padding: 0 5px;
      & > div {
        padding: 0 2px;
        flex: 1;
        div {
          height: 6px;
          border-radius: 3px;
          &.weak {
            background-color: $error;
          }
          &.good {
            background-color: $warn;
          }
          &.strong {
            background-color: $success;
          }
        }
      }
    }
    .user-management-table {
      flex: 1;
      .location {
        border-radius: 3px;
        border: 1px solid $success;
        padding: 2px 10px;
      }
    }
    .sign-google {
      height: 45px;
      display: inline-flex;
      align-items: center;
      background-color: $success;
      cursor: pointer;
      margin-bottom: 15px;
      .img-content {
        height: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        img {
          width: 25px;
        }
      }
      .sign-title {
        padding: 0 10px;
      }
    }
    .google-container {
      flex: 1;
      min-height: 0;
      overflow: auto;
    }
    .google-item {
      margin: 6px 0;
      display: flex;
      align-items: center;
      .remove {
        color: #3185D8;
        font-size: 16px;
        margin-right: 20px;
        cursor: pointer;
      }
      .name-wrapper {
        .email {
          color: #506C92;
          font-size: 14px;
        }
      }
      .warning-span {
        color: red
      }
      .clickable {
        cursor: pointer;
      }
      .link{
        color: dodgerblue;
      }
    }
  }
}