.circular-image-small {
  height: 36px;
  width: 36px;
  border-radius: 50%;
}
.avatar-image-small {
  width: 54px;
  height: 54px;
  border-radius: 12px;
}
.rating-container {
  margin-right: 10px;
  margin-left: 2rem;
  .name {
    color: #30304e;
  }
  .star {
    font-size: 18px;
    color: #ff6869;
  }
  .value {
    color: #8d8d8d;
    font-size: 14px;
    margin-top: 2px;
    margin-left: 5px;
  }
}
.notification-image {
  width: 30px;
  margin-right: 25px;
}
.profile-menu {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  padding: 0 30px 0 10px;
  span {
    margin-right: 5px;
  }
}

