.location-component {
	position: relative;
	.location-dropdown {
		z-index: 100;
		position: absolute;
	}
	.location-item {
		position: relative;
		width: 290px;
		padding: 10px 20px 10px 30px;
		&:hover {
			background-color: $black;
		}
		.circle {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			margin: 0 4px;
		}
		.name {
			width: 170px;
			padding-left: 10px;
			font-size: 16px;
			padding-right: 20px;
			word-break: break-word;
		}
		.time {
			padding-left: 10px;
			font-size: 14px;
			color: #506c92;
		}
		div, span {
			cursor: default;
		}
		.arrow {
			position: absolute;
			top: 20%;
			left: 8px;
		}
		&.selected-drop {
			background-color: inherit;
			border: 2px solid $success;
		}
	}
	.selected.location-item {
		&:hover,
		&.active {
			background-color: $purple;
		}
	}

	&.simple {
		.location-item {
			width: 180px;
			.name {
				font-size: 14px;
				width: 150px
			}
		}
		.location-dropdown {
			.location-item {
				&:hover {
					background-color: $success;
				}	
				&.selected-drop {
					.name {
						color:$success;
					}
					background-color: inherit;
				}
			}
			
		}
	}

	&.report {
		.location-item {
			width: 300px;
			.name {
				font-size: 20px;
				width: 270px;
				font-weight: 300;
			}
			.label {
				padding-left: 10px;
				padding-right: 20px;
				font-size: 16px;
				color: #506C92;
			}
		}
		.location-dropdown {
			.location-item {
				&:hover {
					background-color: $success;
					.label {
						color: $white;
					}
				}	
				&.selected-drop {
					.name, .label {
						color:$success;
					}
					background-color: inherit;
				}
			}
		}		
	}
}

.range-component {
	.show {
		padding: 6px 20px;
		background-color: $black;
		width: 250px;
		margin-bottom: 1px;
		border-radius: 3px;
		.calendar {
			font-size: 16px;
			color: #3185D8;
			margin-right: 10px;
		}
	}
	.control {
		width: 700px;
		position: absolute;
		z-index: 100;
		.MuiPaper-root {
			background-color: $black;
			color: $text-body;
			border-radius: 3px;
		}
		button, span, p {
			color: $text-body;
		}
		button[class*='outlined'] {
			border: 1px solid $white;
		}
		button[class*='filled'] {
			background-color: $purple;
		}
		div[class*='highlighted'] {
			background-color: $purple;
		}
		svg[class*='colorAction'] {
			color: $text-body;
		}
		div[class*='MuiInputBase'] {
			color: $text-body;
			&::before {
				border-bottom: 1px solid $text-body;
			}
			&::after {
				border-bottom: 2px solid $text-body;
			}
			svg {
				color: $text-body;
			}
		}
	}
	&.purple {
		.show {
			background-color: $purple;
		}
		.control {
			.MuiPaper-root {
				background-color: $purple;
				color: $text-body;
			}
			button, span, p {
				color: $text-body;
			}
			button[class*='outlined'] {
				border: 1px solid $black;
			}
			button[class*='filled'] {
				background-color: $black;
			}
			div[class*='highlighted'] {
				background-color: $black;
			}
		}
	}
}

.popover-component {
	position: relative;
	.circle {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		border: 3px solid $success;
	}
}

.progress-component {
	display: flex;
	align-items: center;
	position: relative;
	.value {
		position: absolute;
	}
}

.linear-bar-component {
	display: flex;
	align-items: center;
	position: relative;
}

.locationcard-component {
	width: 250px;
	display: flex;
	flex-direction: column;
	padding: 20px;
	margin: 7px 0;
	background-color: $purple;
	border-radius: 3px;
	border-left: 3px solid $purple;
	cursor: pointer;
	&:hover {
		background-color: #21324A;
		border-left: 3px solid #21324A;
	}
	.circle {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		margin: 0 4px;
	}
	.name {
		width: 170px;
		font-size: 16px;
		word-break: break-word;
		color: #88919e;
	}
	.time {
		font-size: 13px;
		color: #88919e;
		margin-bottom: 10px;
	}
	&.active {
		border-left: 3px solid $success;
		background-color: $black;

		.name {
			color: #ffffff;
		}
		.time {
			color: #506c92;
		}
	}
}

.date-component {
    .MuiInput-underline:before {
      border: 0 !important;
    }
    .MuiInput-underline:after {
      border: 0 !important;
    }
    .MuiInputBase-root {
      width: 150px;
	  height: 35px;
	  background-color: $black;
	  border-radius: 3px;
    }
    input {
	  margin-left: 50px;
	  color: #ffffff;
    }
    button.MuiIconButton-root {
      position: absolute;
	  left: 0;
	  color: $success;
	}
	.MuiSvgIcon-root {
		font-size: 1.2rem;
	}
	&.spaces-date .MuiInputBase-root {
		background-color: $purple;
	}
}

.time-component {
    .MuiInput-underline:before {
      border: 0 !important;
    }
    .MuiInput-underline:after {
      border: 0 !important;
    }
    .MuiInputBase-root {
      width: 150px;
	  height: 35px;
	  background-color: $black;
	  border-radius: 3px;
    }
    input {
	  margin-left: 50px;
	  color: #ffffff;
    }
    button.MuiIconButton-root {
      position: absolute;
	  left: 0;
	  color: $success;
	}
	.MuiSvgIcon-root {
		font-size: 1.2rem;
	}
}

.blackPaper {
	.MuiPaper-root {
		box-shadow: 0px 0px 15px #00000029;
		background-color: $black;
	}
	.MuiListItem-button:hover {
		text-decoration: none;
		background-color: $purple;
	}
	.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
		background-color: $purple;
	}
}

.purplePaper {
	.MuiPaper-root {
		box-shadow: 0px 0px 15px #00000029;
		background-color: $purple;
	}
	.MuiListItem-button:hover {
		text-decoration: none;
		background-color: $black;
	}
	.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
		background-color: $black;
	}
}

.checkbox-tags {
	.tag-container {
		margin-left: -4px;
		margin-right: -4px;
		margin-top: 4px;
	}
}

.tag-component {
	background-color: $black;
	border: 1px solid $success;
	border-radius: 3px;
	padding: 2px 12px 2px 18px;
	margin: 4px;
	.name {
		margin-right: 6px;
	}
	cursor: default;
	.close {
		display: none;
	}
	&:hover {
		background-color: $purple;
		padding: 2px 5px 2px 11px;
		.close {
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background-color: $success;	
		}
	}
}

.tag-component-readonly {
	background-color: $black;
	border: 1px solid $success;
	border-radius: 3px;
	padding: 2px 12px 2px 18px;
	margin: 4px;
	.name {
		margin-right: 6px;
	}
	cursor: default;
	.close {
		display: none;
	}
}

.required {
	color: #f44336
}

.box-title-component {
	.box {
		width: 15px;
		height: 15px;
		border-radius: 3px;
	}
	.title {
		margin-left: 5px;
		font-weight: 300;
		line-height: 16px;
		font-size: 16px;
		color: #FFFFFF;
	}
}