.signup {
	//background: #0c141f url('/assets/images/bg.png') 0% 0% repeat padding-box;

	.signup-card {
		width: 596px;
		border-radius: 3px !important;
		background: #070c13 0% 0% no-repeat padding-box;
		box-shadow: 8px 8px 10px #0000003b;
		.title {
			font-size: 18px;
		}
		.form-wrapper {
			padding: 30px 74px;
			.forget {
				font-size: 16px;
				color: #3185d8;
			}
			.submit {
				background: $purple;
				font-size: 16px;
				color: #ffffff;
			}
		}
		.divider {
			width: 100%;
			position: absolute;
			top: 30px;
			background-color: $light-purple;
			height: 4px;
			border-radius: 3px;
			&.active {
				background-color: #ffffff;
			}
		}
	}
}
