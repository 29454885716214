.menu {
	color: #3185d8;
	position: absolute;
	top: 40px;
	left: 40px;
	z-index: 100;
	cursor: pointer;
	&:hover {
		color: #ffffff;
	}
}
.sidenav {
	position: fixed;
	top: 0;
	height: 100vh;
	background-repeat: no-repeat;
	background-position: top;
	background-size: cover;
	overflow: hidden;
	z-index: 99;
	box-shadow: $elevation-z8;
	width: $sidenav-width;
	background-color: $black;
	.sidenav__hold {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		height: 100%;
		z-index: 3;
		// padding: 30px;
		.scrollable {
			height: 100%;
			display: flex;
			flex-direction: column;
		}
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			z-index: -1;
		}
		.sidenav__title {
			padding: 40px 28px;
			.leftWrapper {
				display: flex;
				align-items: center;
				.sidenav-logo {
					width: 32px;
					margin-right: 12px;
				}
			}
		}
	}
}

.sidenav-mobile {
	.sidenav {
		width: $sidenav-width;
	}
	.sidenav__overlay {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.54);
		z-index: -1;
	}
}

.navigation {
	button.nav-item {
		padding: 0 20px 0 59px;
	}
	.nav-item {
		display: flex;
		height: 48px;
		justify-content: space-between;
		button {
			padding: 0 20px 0 40px;
		}
		&:hover {
			background-color: $purple;
		}
		&.active {
			background-color: $purple;
		}
		> button {
			justify-content: flex-start;
		}
		.item-icon {
			color: #3185d8;
			width: 20px;
		}
		.item-text {
			font-size: 0.875rem;
			font-weight: 500;
			letter-spacing: 0.3px;
		}
		.icon-text {
			font-size: 0.875rem;
			display: inline-block;
			height: 21px;
			width: 21px;
			line-height: 21px;
		}
		.item-arrow {
			span {
				font-size: 18px;
			}
		}
	}
	.submenu {
		position: relative;
		background: rgba($black, 0.12);
		&:before,
		&:after {
			content: '';
			left: 0;
			position: absolute;
			width: 100%;
			height: 2px;
			z-index: 3;
		}
		&:before {
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), transparent);
			top: 0;
		}
		&:after {
			background: linear-gradient(-180deg, rgba(0, 0, 0, 0.06), transparent);
			bottom: 0;
		}
		.nav-item {
			height: 44px;
		}
	}
}
.badge {
	height: 20px;
	padding: 0 8px;
	margin: 0 4px 0 auto;
	border-radius: 12px;
	line-height: 20px;
	font-weight: 500;
}
