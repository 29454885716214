.system-content {
  .location-table {
    .circle {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 2px solid $black;
    }
    .room {
      padding-left: 20px;
      width: 250px;
      overflow-wrap: break-word;
    }
    .device {
      border-radius: 3px;
      border: 1px solid $success;
      padding: 2px 10px;
    }
  }

  .content-container {
    width: 1000px;
    .location-container {
      background-color: $black;
      padding: 15px 24px;
      height: calc(100vh - 150px);
      overflow-x: hidden;
      width: 412px;
      &::-webkit-scrollbar {
        width: 0px;
        background: transparent;
      }
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
  }

  .device-content {
    height: calc(100vh - 150px);
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
    .device-name {
      font-size: 18px;
    }
  }
}