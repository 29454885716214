.layout-full {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.layout-contained, .layout-boxed {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}


.layout-contained {
  .container {
    max-width: $contained-layout-width;
    margin: auto;
    width: 100%;
    @include media(767px) {
      max-width: 100%;
    }
  }
}

.layout-boxed {
  max-width: $contained-layout-width;
  margin: auto;
  box-shadow: $elevation-z12;
  background: $white;
  @include media(767px) {
    max-width: 100%;
    box-shadow: none;
  }
}

.breadcrumb {
  margin-bottom: 20px;
  div {
    color: #383838;
    font-size: 25px;
    font-weight: bold;
  }
  span {
    color: #383838;
    font-size: 15px;
  }
}