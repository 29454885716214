.sidenav,
.topbar,
.content-wrap {
	transition: all 0.3s ease;
}

.sidenav {
	top: 0;
	left: 0;
}

.sidenav .scrollable {
	width: $sidenav-width;
}

.topbar {
	.topbar-hold {
		background: #ff4762;
		height: $topbar-height;
	}
}

.layout1 {
	height: 100%;
	.content-wrap {
		flex: 1 1 auto;
		display: flex;
		overflow: hidden;
		position: relative;
		flex-direction: column;
		height: 100%;
		.topbar {
			top: 0;
			z-index: 96;
			padding: 0 82px 30px 38px;
			.topbar-hold {
				position: relative;
				height: $topbar-height;
				border-radius: inherit;
				padding: 1.5rem 20px 0 20px;
				// z-index: 15;
			}
		}
		.scrollable-content {
			display: flex;
			flex-direction: column;
			position: relative;
			flex: 1;
			overflow-y: auto;
			overflow-x: hidden;
			.content {
				position: relative;
			}
		}
	}

	// Fixed Topbar

	// sidenav full
	&.sidenav-full {
		.menu {
			display: none;
		}
		.sidenav {
			// width: $sidenav-width;
			left: 0;
			.sidenav__toggle {
				// display: block;
			}
		}
		.content-wrap {
			width: calc(100% - #{$sidenav-width});
			vertical-align: top;
			margin-left: $sidenav-width;
			// background-color: #fff9f9;
		}
	}

	// sidenav compact
	&.sidenav-compact {
		.sidenav {
			width: $sidenav-compact-width;
			overflow: hidden;
			.scrollable {
				.navigation {
					.nav-item {
						padding: 0;
						width: 48px;
						overflow: hidden;
						justify-content: center;
						margin-left: 6px;
						border-radius: 2px;
						.item-text,
						.item-arrow,
						.badge {
							display: none;
						}
						> button {
							justify-content: center;
						}
					}
				}
			}
			// COMPACT HOVER
			&:hover {
				width: $sidenav-width;
				.scrollable {
					width: $sidenav-width;
					.navigation {
						button.nav-item {
							padding: 0 20px;
						}
						.nav-item {
							// padding: 0 20px;
							width: 100%;
							justify-content: space-between;
							margin-left: 0;
							border-radius: 0;
							.item-text,
							.item-arrow,
							.badge {
								display: inline-block;
							}
							> button {
								justify-content: flex-start;
							}
						}
					}
				}
			}
		}
		.content-wrap {
			width: calc(100% - #{$sidenav-compact-width});
			vertical-align: top;
			margin-left: $sidenav-compact-width;
		}
	}

	// closed sidenav
	&.sidenav-close {
		.sidenav {
			// width: 0px;
			left: -#{$sidenav-width};
		}
		.content-wrap {
			width: calc(100% - #{$sidenav-close-width});
			vertical-align: top;
			margin-left: $sidenav-close-width;
			.topbar {
				width: 100%;
			}
			.content {
			}
		}
	}

	&.no-sidemenu {
		.content-wrap {
			width: 100%;
			vertical-align: top;
			margin-left: 0;
			.topbar {
				width: 100%;
			}
			.content {
			}
		}
	}

	// mobile sidenav
	&.sidenav-mobile {
		.menu {
			display: none;
		}
		.sidenav {
			width: $sidenav-width;
		}
		.content-wrap {
			width: 100%;
			vertical-align: top;
			margin-left: 0px;
			.topbar {
				width: 100%;
			}
		}
	}
}
