.spaces-content {
  .description {
    flex-wrap: wrap;
    .item {
      width: 50%;
      text-align: center;
      margin: 15px 0 20px;
      .value {
        font-size: 26px;
      }
      .memo {
        font-size: 13px;
      }
    }
  }
  .schedule {
    background-color: $purple;
    padding: 8px 20px;
    border-radius: 3px;
    margin: 10px 0;
    .title {
      font-size: 13px;
      color: $error;
    }
    .time {
      font-size: 13px;
    }
  }
  .schedule-content {
    width: 100%;
    padding: 30px;
    background-color: $black;
    .schedule-header {
      .show-type {
        display: flex;
        align-items: center;
        margin-left: 30px;
        .type-circle {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 10px;
          background-color: $occupied;
        }
      }
      .btn {
        background: $purple;
        color: #ffffff;
      }
    }
    .schedule-wrapper {
      font-size: 13px;
      margin-top: 20px;
      margin-bottom: 60px;
      .timeValue {
        width: 50px;
        .time {
          color: $light-purple;
          height: 50px;
        }
        .time:last-child {
          height: 0px;
        }
      }
      .timeContent {
        position: relative;
        flex: 1;
        .content {
          width: 100%;
          position: absolute;
          color: #3E4F65;
          border-radius: 3px;
          background-color: $purple;
          padding: 5px 10px;
          &.bar {
            padding: 0;
            background-color: $error;
          }
          &.border {
            border-left: 3px solid $error;
            color: $error;
          }
        }
      }
      .timeline {
        width: 4px;
        position: relative;
        margin-left: 10px;
        .line {
          width: 100%;
          position: absolute;
          border-radius: 3px;
          background-color: $occupied;
        }
      }
    }
  }
}