.border-radius-0 {
  border-radius: 0px !important;
  overflow: hidden;
}
.border-radius-3 {
  border-radius: 3px !important;
  overflow: hidden;
}
.border-radius-4 {
  border-radius: 4px !important;
  overflow: hidden;
}
.border-radius-8 {
  border-radius: 8px !important;
  overflow: hidden;
}
.border-radius-12 {
  border-radius: 12px !important;
  overflow: hidden;
}
.border-radius-circle {
  border-radius: 50% !important;
  overflow: hidden;
}
.border-none {
  border: none !important;
}
.border-bottom-gray-1 {
  border-bottom: solid 1px #e2e2e2;
}
.border-bottom-1 {
  border-bottom: solid 1px #f5f5f5;
}
